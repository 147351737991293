<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container order-box">
      <p class="title">订单支付</p>

      <div class="content">
        <p class="info">
          确认订单信息 <span>请在2小时内完成支付，否则订单会被自动取消</span>
        </p>
        <div class="userinfo">
          <img :src="userinfo.avatar" alt="" />
          <div>
            <p class="phone">购买账号 :{{ userinfo.mobile }}</p>
            <p class="tip">
              注意：购买后不支持退款、转让，请确认有效期后再提交订单
            </p>
          </div>
        </div>
        <div class="paytype">
          <p>支付方式</p>
          <div><img src="@/assets/image/paywx.png" alt="" /> 微信</div>
        </div>
      </div>
      <div class="payinfo" v-if="orderdeil.outTradeNo">
        <img
          class="paypic"
          :src="`https://www.hrclass.com.cn/web/pay/wechat/web?outTradeNo=${orderdeil.outTradeNo}&price=${orderdeil.payPrice}&sourceId=${orderdeil.sourceId}`"
          alt=""
        />
        <p>请使用微信扫码支付</p>
        <p class="price">
          ￥ <span>{{ orderdeil.payPrice }}</span>
        </p>
      </div>
    </div>
    <Otherfooter />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import { getUser } from "@/plugins/auth.js";

import Otherfooter from "@/components/Otherfooter.vue";
import { api_queryHrOrder } from "@/apis/apis.js";
export default {
  components: {
    Heard,
    // Tip,
    Otherfooter,
  },
  data() {
    return {
      userinfo: "",
      orderdeil: {},
      time: "",
    };
  },
  created() {
    this.getorderdeil();
    this.userinfo = getUser();
  },
  methods: {
    //查询订单信息
    getorderdeil() {
      this.time = setInterval(() => {
        api_queryHrOrder({
          userId: getUser().id,
          id: this.$route.params.id,
        }).then((res) => {
          console.log(res);
          this.orderdeil = res.data.data;
          if (res.data.data.status == 1) {
            clearInterval(this.time);
            setTimeout(() => {
              this.$message({
                message: "支付成功---",
                type: "success",
              });
            }, 2000);
            this.$router.push("/order");
          }
        });
      }, 2000);
    },
  },

  // beforeRouteLeave(to, form, next) {
  //   console.log(111);
  //   next();
  // },
};
</script>

<style lang="less" scoped>
.order-box {
  width: 1200px;
  height: 764px;
  border: 1px solid #f2f2f2;
  margin-top: 40px;
  padding: 20px;
  .title {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    border-bottom: 1px solid #f2f2f2;
  }
  .content {
    margin-top: 36px;
    .info {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 60px;
      span {
        font-size: 18px;
        font-weight: 400;
        color: #7b7b7b;
      }
    }
    .userinfo {
      display: flex;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 20px;
      }
      .phone {
        height: 26px;
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }
      .tip {
        font-size: 16px;
        font-weight: 400;
        color: #7b7b7b;
        margin-top: 12px;
      }
    }
    .paytype {
      p {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        margin: 55px 0px 34px;
      }
      div {
        display: flex;
        align-items: center;
        img {
          width: 32px;
          height: 32px;
          margin-right: 20px;
        }
      }
    }
  }
  .payinfo {
    margin-top: 40px;
    display: flex;
    align-items: center;
    img {
      width: 140px;
      height: 140px;
      margin-right: 50px;
    }
    .price {
      margin-left: 40px;
      color: red;
      font-weight: bold;
      span {
        font-size: 30px;
      }
    }
  }
}
.page {
  margin-top: 40px;
  text-align: center;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5751fb !important;
  color: #fff;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #5751fb !important;
}
.el-button--one {
  border: 0;
  color: rgb(150, 149, 149);
}
</style>